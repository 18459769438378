import React, { useState } from 'react';

export const StateContext = React.createContext();

const Provider = props => {
  const [storeSegment, setStoreSegment] = useState();
  const [eventType, setEventType] = useState();
  const [eventDateFilter, setEventDateFilter] = useState();

  return (
    <StateContext.Provider value={{
        storeSegment, setStoreSegment: (id) => setStoreSegment(id),
        eventType, setEventType: (type) => setEventType(type),
        eventDateFilter, setEventDateFilter: (date) => setEventDateFilter(date),
    }}>
        {props.children}
    </StateContext.Provider>
  )
};

export default ({ element }) => (
    <Provider>
        {element}
    </Provider>
);
